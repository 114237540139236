import React, { useEffect, useRef, useState } from 'react'

import { AdminTableToolbar } from 'pages/TeamMembersPage/AdminTableToolbar/AdminTableToolbar'
import BulkActionBar from 'pages/TeamMembersPage/BulkActionBar/BulkActionBar'

import Button from 'components/Button'
import { useGlobalModal } from 'components/GlobalModal'
import { SeatManagementMode } from 'components/ManageSeatSidePanel'
import { useMultiInviteModal } from 'components/MultiInviteModal/useMultiInviteModal'
import SeatAvatar from 'components/SeatAvatar'
import { AvailableSeatAvatar } from 'components/SeatAvatar/AvailableSeatAvatar'
import { PendingInviteSeatAvatar } from 'components/SeatAvatar/PendingInviteSeatAvatar'
import {
  AccordionConfigType,
  ColumnConfigType,
  TableComponent,
  useFilteredSearchedSortedData
} from 'components/SortSearchFilterTable/SortSearchFilterTable'
import { displayToast } from 'components/Toast'
import DropdownContextMenu from 'components/dropdowns/Dropdown/DropdownContextMenu'

import { MIN_WIDTH_TAILWIND_MD } from 'constants/breakpoints'

import {
  TeamMembersPageSubscriptionFragment,
  TeamMembersPageSubscriptionSeatFragment
} from 'gql'

import { useFeatureFlags } from 'hooks/useFeatureFlags'
import useMediaQuery from 'hooks/useMediaQuery'

interface SeatActionsProps {
  seat: TeamMembersPageSubscriptionSeatFragment
  onAssignCohortPassAction(seat: SeatActionsProps['seat']): void
  onSeatAssignAction(seat: SeatActionsProps['seat']): void
  hasCohortAccess: boolean
  isProvisionedByScim: boolean
}

const SeatActions = ({
  seat,
  onAssignCohortPassAction,
  onSeatAssignAction,
  hasCohortAccess,
  isProvisionedByScim
}: SeatActionsProps) => {
  const isAccordionView = useMediaQuery(`(max-width: ${MIN_WIDTH_TAILWIND_MD})`)

  const handleSeatAssign = () => {
    onSeatAssignAction(seat)
  }

  const handleCopyInviteLink = () => {
    navigator.clipboard.writeText(
      `${window.location.origin}/?mtoken=${seat.subscriptionMember?.token}`
    )
    displayToast(`Copied invite link to clipboard for ${seat.subscriptionMember?.email}.`)
  }

  const isEnrolled = seat.subscriptionMember?.user.is.enrolledInLiveProgram

  if (isAccordionView) {
    return (
      <div className="flex flex-col space-y-2 mt-2">
        {seat.subscriptionMember?.status === 'pending' && (
          <Button
            size="small"
            className="border border-gray-300 hover:bg-gray-50 hover:border-gray-500 px-2 h-[40px]"
            variant="outline"
            onClick={handleCopyInviteLink}
            dataTest={`seat-${seat.id}-invite-button`}
          >
            Copy Invite link
          </Button>
        )}
        {!isProvisionedByScim && (
          <Button
            size="small"
            className="border border-gray-300 hover:bg-gray-50 hover:border-gray-500 px-2 h-[40px]"
            variant="outline"
            onClick={handleSeatAssign}
            dataTest={`seat-${seat.id}-invite-button`}
          >
            {seat.subscriptionMember ? 'Remove Member' : 'Invite Member'}
          </Button>
        )}
        {!isEnrolled && (
          <Button
            size="small"
            className="border border-gray-300 hover:bg-gray-50 hover:border-gray-500 px-2 h-[40px]"
            variant="outline"
            onClick={() => onAssignCohortPassAction(seat)}
            dataTest={`assign-cohort-pass-seat-${seat.id}`}
          >
            {hasCohortAccess ? 'Remove Live Course Pass' : 'Assign Live Course Pass'}
          </Button>
        )}
      </div>
    )
  }

  return (
    <div className="flex flex-shrink-0 items-center space-x-2">
      {seat.subscriptionMember?.status === 'pending' && (
        <Button
          size="x-small"
          className="whitespace-nowrap"
          variant="outline"
          onClick={handleCopyInviteLink}
          dataTest={`seat-${seat.id}-invite-button`}
        >
          Copy Invite link
        </Button>
      )}
      {!isProvisionedByScim && !seat.subscriptionMember && (
        <Button
          size="x-small"
          variant="text-only"
          onClick={handleSeatAssign}
          dataTest={`seat-${seat.id}-invite-button`}
          className="text-[#1A6C69] hover:text-[#1A6C69] hover:underline hover:bg-transparent -ml-4 py-2"
        >
          Invite user
        </Button>
      )}
      {seat.subscriptionMember && (
        <DropdownContextMenu
          positions={['left']}
          dataTest={`seat-${seat.id}-dropdown-button`}
          dismissOnClick
        >
          {!isProvisionedByScim && (
            <DropdownContextMenu.DropdownItem
              data-test="dropdown-context-menu-unassign-assign-seat"
              text={'Remove Member'}
              onClick={handleSeatAssign}
            />
          )}
          {!isEnrolled && (
            <DropdownContextMenu.DropdownItem
              data-test={`assign-cohort-pass-seat-${seat.id}`}
              text={
                hasCohortAccess ? 'Remove Live Course Pass' : 'Assign Live Course Pass'
              }
              onClick={() => onAssignCohortPassAction(seat)}
            />
          )}
        </DropdownContextMenu>
      )}
    </div>
  )
}

export interface SeatsListProps
  extends Omit<SeatActionsProps, 'seat' | 'hasCohortAccess'> {
  seats: TeamMembersPageSubscriptionSeatFragment[]
  subscriptionHasDomainName: boolean
  recentlyUpdatedSeat: TeamMembersPageSubscriptionSeatFragment | null
  isProvisionedByScim: boolean
  dispatch: React.Dispatch<any>
  sidePanelIsOpen: boolean
  sidePanelMode: SeatManagementMode | null
  currentUserId: string
  totalSeatCount: number
  assignedSeatCount: number
  subscription: TeamMembersPageSubscriptionFragment
  subscriptionToken: string
}

const highlightRecentlyUpdatedSeatRow = ({
  seat,
  tableRef
}: {
  seat?: TeamMembersPageSubscriptionSeatFragment | null
  tableRef?: React.RefObject<HTMLTableElement> | null
}) => {
  if (seat) {
    const updatedSeatRow =
      tableRef?.current && tableRef.current.querySelector(`[data-id='${seat.id}']`)

    if (updatedSeatRow) {
      updatedSeatRow.scrollIntoView({ behavior: 'smooth', block: 'nearest' })

      updatedSeatRow.classList.add('bg-rb-blue-50')
      setTimeout(() => {
        updatedSeatRow.classList.remove('bg-rb-blue-50')
      }, 5000)
    }
  }
}

export const SeatsList = ({
  seats,
  subscriptionHasDomainName,
  onAssignCohortPassAction,
  onSeatAssignAction,
  recentlyUpdatedSeat,
  isProvisionedByScim,
  dispatch,
  sidePanelIsOpen,
  sidePanelMode,
  currentUserId,
  totalSeatCount,
  assignedSeatCount,
  subscription,
  subscriptionToken
}: SeatsListProps) => {
  const { teamInvitationInvites } = useFeatureFlags()
  const [sortField, setSortField] = useState<string>('name')
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc')
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedItems, setSelectedItems] = useState<string[]>([])
  const [isFilterExpanded, setIsFilterExpanded] = useState(false)
  const [filters, setFilters] = useState({
    coursePass: [] as string[],
    enrollment: [] as string[],
    status: [] as string[]
  })
  const [successfullyInvitedEmails, setSuccessfullyInvitedEmails] = useState<string[]>([])

  const { openMultiInviteModal } = useMultiInviteModal(subscription)
  const { isGlobalModalOpen } = useGlobalModal()

  const searchFields = [
    'subscriptionMember.user.fullName',
    'subscriptionMember.user.email',
    'subscriptionMember.user.workEmail'
  ]

  useEffect(() => {
    if (sidePanelMode === SeatManagementMode.ACTION_FINISHED) {
      setSelectedItems([])
    }
  }, [sidePanelMode])

  type SeatType = TeamMembersPageSubscriptionSeatFragment

  const getSeatAvatar = (subscriptionMember: SeatType['subscriptionMember']) => {
    const avatarSize = 38
    if (subscriptionMember && subscriptionMember.status === 'pending') {
      return (
        <PendingInviteSeatAvatar
          emailStyles="text-sm font-semibold text-[#757B74] md:w-auto md:max-w-max"
          email={subscriptionMember.user.workEmail || subscriptionMember.user.email}
          noEmailTruncate={true}
          avatarSize={avatarSize}
        />
      )
    } else if (subscriptionMember) {
      const nameToDisplay =
        subscriptionMember.user.fullName || subscriptionMember.user.email

      const isCurrentUser = subscriptionMember.user.isCurrentUser
      const isAssistantAdmin = subscriptionMember.user.is.assistantAdmin

      return (
        <SeatAvatar
          avatarSize={avatarSize}
          nameStyles="text-sm md:w-auto md:max-w-max flex items-center"
          emailStyles="text-sm font-semibold text-[#757B74] md:w-auto md:max-w-max"
          noEmailTruncate={true}
          memberName={`${nameToDisplay} ${isCurrentUser ? '(You)' : ''}`}
          assistantAdmin={isAssistantAdmin}
          email={subscriptionMember.user.workEmail || subscriptionMember.user.email}
          isWorkEmail={!!subscriptionMember.user.workEmail}
          subscriptionHasDomainName={subscriptionHasDomainName}
          avatarUrl={subscriptionMember.user.profile.avatarUrl}
        />
      )
    }

    return (
      <AvailableSeatAvatar
        className="md:mt-1"
        avatarSize={avatarSize - 4}
        avatarWrapperSize={avatarSize - 4}
      />
    )
  }

  const handleCheckboxChange = (item: TeamMembersPageSubscriptionSeatFragment) => {
    setSelectedItems((prevSelected) => {
      const itemId = item.id
      const newSelected = prevSelected.includes(itemId)
        ? prevSelected.filter((id) => id !== itemId)
        : [...prevSelected, itemId]
      return newSelected
    })
  }

  // Highlight recently updated seat
  const tableRef = useRef<HTMLTableElement>(null)
  useEffect(() => {
    highlightRecentlyUpdatedSeatRow({ seat: recentlyUpdatedSeat, tableRef })
  }, [recentlyUpdatedSeat])

  const columns: ColumnConfigType<SeatType>[] = [
    {
      id: 'name',
      header: 'Name',
      accessor: 'subscriptionMember',
      sortable: true,
      render: (value) => getSeatAvatar(value),
      sortFunction: (a, b) => {
        const getSortValue = (seat: SeatType) => {
          if (!seat.subscriptionMember) return 0 // Available seats
          if (seat.subscriptionMember.status === 'pending') return 1 // Pending seats
          return 2 // Active seats
        }

        const aValue = getSortValue(a)
        const bValue = getSortValue(b)

        if (aValue !== bValue) {
          return aValue - bValue
        }

        // If both are available seats, maintain their original order
        if (aValue === 0) return 0

        // For pending seats, sort by email
        if (aValue === 1) {
          const aEmail = a.subscriptionMember?.email?.toLowerCase() || ''
          const bEmail = b.subscriptionMember?.email?.toLowerCase() || ''
          return aEmail.localeCompare(bEmail)
        }

        // For active seats, sort by fullName
        const aName = a.subscriptionMember?.user?.fullName?.toLowerCase() || ''
        const bName = b.subscriptionMember?.user?.fullName?.toLowerCase() || ''
        return aName.localeCompare(bName)
      },
      rowDataTestId: (seat) =>
        seat.subscriptionMember
          ? `occupied-seat-${seat.id}`
          : `unoccupied-seat-${seat.id}`,
      rowDataId: (seat) => seat.id,
      hideCheckbox: (seat) => !teamInvitationInvites && !seat.subscriptionMember
    },
    {
      id: 'enrollment',
      header: 'Enrollment',
      accessor: 'subscriptionMember.user.currentEnrolledCourse.course.title',
      sortable: true,
      render: (value) => {
        return (
          <span className="text-sm">{value ? `Enrolled: ${value}` : 'Not Enrolled'}</span>
        )
      },
      sortFunction: (a, b) => {
        const aEnrolled = a.subscriptionMember?.user?.is?.enrolledInActiveCourse
        const bEnrolled = b.subscriptionMember?.user?.is?.enrolledInActiveCourse
        if (aEnrolled === bEnrolled) {
          const aTitle =
            a.subscriptionMember?.user?.currentEnrolledCourse?.course?.title || ''
          const bTitle =
            b.subscriptionMember?.user?.currentEnrolledCourse?.course?.title || ''
          return aTitle.localeCompare(bTitle)
        }
        return aEnrolled ? 1 : -1
      }
    },
    {
      id: 'coursePass',
      header: 'Course Pass',
      accessor: 'hasCohortAccess',
      sortable: true,
      render: (value) => <span className="text-sm">{value ? 'Yes' : 'No'}</span>
    },
    {
      id: 'status',
      header: 'Status',
      accessor: 'subscriptionMember.status',
      sortable: true,
      render: (value, item) => {
        return (
          <div className="flex items-center space-x-4">
            {value && (
              <span
                className={`px-2 py-1 inline-flex text-xs leading-5 font-normal rounded-md ${
                  value === 'pending'
                    ? 'bg-[rgb(246,246,245)] text-[rgb(45,47,47)] border border-[rgb(187,189,186)]'
                    : 'bg-[rgb(244,248,218)] text-[rgb(45,47,47)] border border-[rgb(202,221,72)]'
                }`}
              >
                {value === 'pending' ? 'Pending' : 'Active'}
              </span>
            )}
            <SeatActions
              seat={item}
              onAssignCohortPassAction={onAssignCohortPassAction}
              onSeatAssignAction={onSeatAssignAction}
              hasCohortAccess={item.hasCohortAccess}
              isProvisionedByScim={isProvisionedByScim}
            />
          </div>
        )
      },
      sortFunction: (a, b) => {
        const statusOrder: { [key: string]: number } = {
          '': 0,
          'pending': 1,
          'active': 2
        }
        const aStatus = a.subscriptionMember?.status || ''
        const bStatus = b.subscriptionMember?.status || ''
        if (statusOrder[aStatus] === statusOrder[bStatus]) {
          const aTitle =
            a.subscriptionMember?.user?.currentEnrolledCourse?.course?.title || ''
          const bTitle =
            b.subscriptionMember?.user?.currentEnrolledCourse?.course?.title || ''
          return aTitle.localeCompare(bTitle)
        }
        return statusOrder[aStatus] - statusOrder[bStatus]
      }
    }
  ]

  const accordionData: AccordionConfigType<SeatType> = {
    hideCheckbox: (item: SeatType) => {
      return !teamInvitationInvites && !item.subscriptionMember
    },
    header: (item: SeatType) => (
      <div className="flex items-center py-2">
        {getSeatAvatar(item.subscriptionMember)}
      </div>
    ),
    fields: [
      {
        label: 'Course Pass',
        value: (item: SeatType) => (
          <span className="text-sm">{item.hasCohortAccess ? 'Yes' : 'No'}</span>
        )
      },
      {
        label: 'Enrollment',
        value: (item: SeatType) => (
          <span className="text-sm">
            {item.subscriptionMember?.user.currentEnrolledCourse?.course?.title
              ? `Enrolled: ${item.subscriptionMember?.user.currentEnrolledCourse.course.title}`
              : 'Not Enrolled'}
          </span>
        )
      },
      {
        label: 'Status',
        value: (item: SeatType) => (
          <span
            className={`px-2 py-1 inline-flex text-xs leading-5 font-normal rounded-md ${
              item.subscriptionMember?.status === 'pending'
                ? 'bg-[rgb(246,246,245)] text-[rgb(45,47,47)] border border-[rgb(187,189,186)]'
                : 'bg-[rgb(244,248,218)] text-[rgb(45,47,47)] border border-[rgb(202,221,72)]'
            }`}
          >
            {item.subscriptionMember?.status === 'pending' ? 'Pending' : 'Active'}
          </span>
        )
      },
      {
        label: 'Actions',
        value: (item: TeamMembersPageSubscriptionSeatFragment) => (
          <SeatActions
            seat={item}
            onAssignCohortPassAction={onAssignCohortPassAction}
            onSeatAssignAction={onSeatAssignAction}
            hasCohortAccess={item.hasCohortAccess}
            isProvisionedByScim={isProvisionedByScim}
          />
        )
      }
    ]
  }

  const customFilters: Partial<
    Record<string, (item: SeatType, filterValues: string[]) => boolean>
  > = {
    'subscriptionMember.status': (item: SeatType, filterValues: string[]) => {
      if (filterValues.length === 0) return true

      if (filterValues.includes('available') && !item.subscriptionMember?.status) {
        return true
      }

      return filterValues.includes(item.subscriptionMember?.status || '')
    }
  }

  const filteredSearchedSortedData = useFilteredSearchedSortedData<SeatType>(
    seats,
    searchTerm,
    {
      'hasCohortAccess': filters.coursePass.map((value) =>
        value === 'Yes' ? 'true' : 'false'
      ),
      'subscriptionMember.user.is.enrolledInActiveCourse': filters.enrollment.map(
        (value) => (value === 'Enrolled' ? 'true' : 'false')
      ),
      'subscriptionMember.status': filters.status
    },
    searchFields,
    sortField,
    sortOrder,
    columns,
    customFilters
  )

  const handleDownload = async () => {
    try {
      const headers = ['Name', 'Email', 'Course Pass', 'Enrollment', 'Status']
      const csvContent = [
        headers.join(','),
        ...filteredSearchedSortedData.map((item) =>
          [
            item.subscriptionMember?.user.fullName || 'Unassigned',
            item.subscriptionMember?.user.email || '',
            item.hasCohortAccess ? 'Yes' : 'No',
            item.subscriptionMember?.user.currentEnrolledCourse?.course?.title ||
              'Not Enrolled',
            item.subscriptionMember?.status || 'Unassigned'
          ].join(',')
        )
      ].join('\n')
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
      const fileName = 'user_data.csv'
      // Use the File System Access API if available
      if ('showSaveFilePicker' in window) {
        const handle = await (window as any).showSaveFilePicker({
          suggestedName: fileName,
          types: [
            {
              description: 'CSV File',
              accept: { 'text/csv': ['.csv'] }
            }
          ]
        })
        const writable = await handle.createWritable()
        await writable.write(blob)
        await writable.close()
      } else {
        // Fall back to the older method
        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.download = fileName
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(url)
      }
    } catch (error) {
      console.error('Failed to download CSV:', error)
      // Here you could show an error message to the user
    }
  }

  const hideAllCheckboxes = !seats.some((seat) => seat.subscriptionMember)

  const selectedUsersToRemove = selectedItems.filter((id) => {
    const seat = seats.find((s) => s.id === id)
    return seat?.subscriptionMember?.status
  })

  const selectedRemoveUsersCount = selectedUsersToRemove.length

  const selectedUsersToRemind = selectedItems.filter((id) => {
    const seat = seats.find((s) => s.id === id)
    return seat?.subscriptionMember?.status === 'pending'
  })

  const selectedRemindUserCount = selectedUsersToRemind.length

  const selectedUsersToInvite = selectedItems.filter((id) => {
    const seat = seats.find((s) => s.id === id)
    return seat && !seat.subscriptionMember
  })

  const selectedInviteUserCount = selectedUsersToInvite.length

  const handleOpenMultiInvitesFromBulkActionBar = () => {
    openMultiInviteModal(
      currentUserId,
      totalSeatCount,
      assignedSeatCount,
      subscription,
      subscriptionToken,
      true,
      () => {
        setSelectedItems([])
      }
    )
  }

  useEffect(() => {
    if (successfullyInvitedEmails.length > 0 && selectedItems.length > 0) {
      setSelectedItems((prevSelected) =>
        prevSelected.filter((id) => {
          const seat = seats.find((s) => s.id === id)
          if (
            seat?.subscriptionMember?.status === 'pending' &&
            successfullyInvitedEmails.includes(seat?.subscriptionMember?.user?.email)
          ) {
            return false
          }
          return true
        })
      )
      setSuccessfullyInvitedEmails([])
    }
  }, [successfullyInvitedEmails, seats, selectedItems])

  const handleSelectAll = () => {
    if (selectedItems.length === seats.length) {
      setSelectedItems([])
    } else {
      setSelectedItems(seats.map((seat) => seat.id))
    }
  }

  return (
    <div className="space-y-4">
      <AdminTableToolbar
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        selectedCoursePass={filters.coursePass}
        setSelectedCoursePass={(selected) =>
          setFilters((prev) => ({ ...prev, coursePass: selected }))
        }
        selectedEnrollment={filters.enrollment}
        setSelectedEnrollment={(selected) =>
          setFilters((prev) => ({ ...prev, enrollment: selected }))
        }
        selectedStatus={filters.status}
        setSelectedStatus={(selected) =>
          setFilters((prev) => ({ ...prev, status: selected }))
        }
        isMobile={useMediaQuery(`(max-width: ${MIN_WIDTH_TAILWIND_MD})`)}
        onDownload={handleDownload}
        isFilterExpanded={isFilterExpanded}
        setIsFilterExpanded={setIsFilterExpanded}
        onManageInvites={() => {
          setSelectedItems([])
          openMultiInviteModal(
            currentUserId,
            totalSeatCount,
            assignedSeatCount,
            subscription,
            subscriptionToken
          )
        }}
      />
      <div className="min-h-0 md:max-h-[600px] overflow-y-auto">
        <TableComponent
          tableRef={tableRef}
          tableDataTestId="seat-list-table"
          data={filteredSearchedSortedData}
          columns={columns}
          sortField={sortField}
          sortOrder={sortOrder}
          setSortField={setSortField}
          setSortOrder={setSortOrder}
          checkboxAccessor={(item) => item.id}
          handleCheckboxChange={handleCheckboxChange}
          hideAllCheckboxes={hideAllCheckboxes}
          accordionData={accordionData}
          selectedItems={selectedItems}
          showSelectAllCheckbox={teamInvitationInvites}
          handleSelectAll={handleSelectAll}
        />
      </div>
      {selectedItems.length > 0 && !sidePanelIsOpen && !isGlobalModalOpen && (
        <BulkActionBar
          selectedCount={selectedItems.length}
          onRemoveUsers={() => {
            dispatch({
              type: 'BULK_UNASSIGN_SEATS',
              payload: {
                bulkSeatsBeingManaged: seats.filter((seat) =>
                  selectedUsersToRemove.includes(seat.id)
                )
              }
            })
          }}
          onSendInviteReminders={() => {
            dispatch({
              type: 'BULK_RESEND_INVITATIONS',
              payload: {
                bulkSeatsBeingManaged: seats.filter((seat) =>
                  selectedUsersToRemind.includes(seat.id)
                )
              }
            })
          }}
          onInviteToSeats={() => {
            handleOpenMultiInvitesFromBulkActionBar()
          }}
          onClose={() => setSelectedItems([])}
          selectedRemoveUserCount={selectedRemoveUsersCount}
          selectedInviteUserCount={selectedInviteUserCount}
          selectedRemindUserCount={selectedRemindUserCount}
        />
      )}
    </div>
  )
}
