import { sub } from 'date-fns'
import { useEffect, useRef } from 'react'

import ButtonMarketingCTA from 'domains/Sanity/marketingSite/ButtonMarketingCTA'

import Modal from 'components/Modal'

import { MAX_WIDTH_TAILWIND_XS } from 'constants/breakpoints'

import { useUserTraitsQuery } from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'
import useLocalStorage from 'hooks/useLocalStorage'
import useMediaQuery from 'hooks/useMediaQuery'
import { trackModalDismissed, trackModalDisplayed } from 'hooks/useModalTracking'

import { ReactComponent as ChromeLogo } from 'images/marketing-extension/logo--chrome.svg'
import extensionImage from 'images/marketing-extension/slide1.png'

const lessthan7DaysOld = (createdAtString: string) => {
  const sevenDaysAgoDate = sub(new Date(), { days: 7 })
  const userCreatedDate = new Date(Date.parse(createdAtString))
  return userCreatedDate > sevenDaysAgoDate
}
/**
 * InstallExtensionModal
 * @returns a modal with CTA to install the Reforge Extension
 * */

export const InstallExtensionModal = () => {
  const currentUser = useAssertCurrentUser()
  const isMobile = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_XS})`)
  const [hasDismissedModal, setHasDismissedModal] = useLocalStorage(
    'has-dismissed-install-extension-modal',
    false
  )
  const skipModal =
    hasDismissedModal || isMobile || lessthan7DaysOld(currentUser.createdAt)
  const eventTriggered = useRef(false)

  const { data } = useUserTraitsQuery({
    variables: { id: currentUser.id, traitKeys: ['extension_has_signed_in_ever'] },
    skip: skipModal
  })

  // We don't show the modal until we get a response from the server
  const hasUsedExtension =
    typeof data === 'undefined'
      ? true
      : Boolean(data.user?.profileTraits?.extension_has_signed_in_ever)

  const onClose = () => {
    trackModalDismissed({
      modal_group: 'homepage',
      modal_name: 'install_extension_modal'
    })
    setHasDismissedModal(true)
  }

  useEffect(() => {
    if (!skipModal && !hasUsedExtension && !eventTriggered.current) {
      trackModalDisplayed({
        modal_group: 'homepage',
        modal_name: 'install_extension_modal'
      })
      eventTriggered.current = true
    }
  }, [skipModal, hasUsedExtension])

  // hasUsedExtension should be at the end of the logic
  if (skipModal || hasUsedExtension) {
    return null
  }

  return (
    <Modal
      isOpen={true}
      header={true}
      handleClose={onClose}
      className="w-[360px] sm:w-[360px] md:w-[720px] !max-w-[720px] rounded-2xl bg-[#F5F5F0]"
      closeOnEscape
    >
      <div className="flex flex-row mb-[37px]">
        <div className="w-[264px] ml-[54px] mr-[42px] mt-5">
          <h3 className="font-polysans text-[32px] font-semibold leading-[110%] mb-6">
            Reforge in
            <br />
            your work.
          </h3>
          <ul className="text-sm leading-6 mb-12 pl-5">
            <li className="mb-[14px]">
              Get instant feedback and suggested edits to your documents.
            </li>
            <li>Generate drafts in seconds using Reforge templates.</li>
          </ul>
          <ButtonMarketingCTA
            href="https://chromewebstore.google.com/detail/reforge-extension/iheecpmppehgghpjbhdpgjdllemcmdnk"
            location="homepage"
            ctaText="Install Chrome Extension"
            target="_blank"
            className="text-sm font-medium rounded-[10px]"
            onClick={onClose}
          >
            <div className="flex items-center">
              <ChromeLogo className="w-6 h-6 mr-3" />
              <span>Install&nbsp;Chrome&nbsp;Extension</span>
            </div>
          </ButtonMarketingCTA>
        </div>
        <img
          src={extensionImage}
          width="322"
          height="361"
          alt="Extension Illustration"
          className="hidden md:block"
        />
      </div>
    </Modal>
  )
}

export default InstallExtensionModal
